.about-us-box .trader__searchBox {
  top: 55px;
}
.about-us-box .account-login {
  top: 70px;
}
.about__box {
  display: flex;
  justify-content: space-between;
}
.about__page {
  background-color: #fff;
  padding: 40px;
}
.about__details-box {
  width: 54%;
}
.about__svg {
  width: 30%;
}
.about__details-box h3 {
  color: var(--primary-color);
}
.about__details {
  background-color: #ffe7ff91;
  border-radius: 5px;
  margin: 5px 0;
  padding: 30px 20px;
}
.about__details p {
  font-size: 13px;
}

@media screen and (max-width: 1088px) {
  .about__svg {
    width: 35%;
  }
}
@media screen and (max-width: 1013px) {
  .about__page {
    padding: 20px;
  }
  .about__box {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 965px) {
  .about__svg {
    width: 40%;
  }
}

@media screen and (max-width: 786px) {
  .about__box {
    flex-direction: column;
    align-items: center;
  }
  .about__svg {
    width: 60%;
  }
  .about__details-box {
    width: 80%;
    margin: 30px 0;
  }
}

@media screen and (max-width: 583px) {
  .about__details-box {
    width: 100%;
  }
  .about__svg {
    width: 90%;
  }
}
