.errorDivBox {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000081;
  width: 100%;
  height: 100vh;
}
.errorBoxContent2 {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
.errorBoxContent2 span {
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .errorBoxContent2 {
    padding: 10px;
  }
}
