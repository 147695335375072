.popup-modal {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.644);
  z-index: 10000;
  transition: all 0.4s ease-in-out;
}
.modal-content {
  width: 40%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-color);
  border-radius: 5px;
  padding: 30px;
  z-index: 20000;
}
.modal-content h2 {
  font-size: 16px;
}
.iconcolor {
  color: var(--red-color);
  margin-right: 5px;
  font-size: 14px;
}

.pop-info {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.pop-info p {
  font-weight: 500;
  font-size: 14px;
}
.delete-btns {
  margin: 20px 0;
}
.delete-btns button {
  width: 150px;
  height: 40px;
  cursor: pointer;
  border-radius: 3px;
}
.btn-danger {
  background-color: var(--red-color);
  border: none;
  color: var(--white-color);
  margin-left: 6px;
}
.notifications {
  margin: 20px 0;
  text-align: center;
}

@media screen and (max-width: 848px) {
  .modal-content {
    width: 90%;
  }

  .delete-btns button {
    width: 120px;
    height: 30px;
    cursor: pointer;
    border-radius: 3px;
  }
}
