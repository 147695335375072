.loader-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(48, 48, 48, 0.781);
  transition: all 0.4s ease-in-out;
  z-index: 100000;
}
.loader-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader__img {
  display: inline-block;
  animation: loadIcon 1s infinite ease-in-out;
  backface-visibility: hidden;
}

@keyframes loadIcon {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
