.joinContainer {
  width: 35%;
  margin: 70px auto;
}
.logoJoin {
  text-align: center;
}
.joinBox {
  background-color: #fff;
  padding: 15px 20px;
  margin: 17px 0;
  border-radius: 4px;
  text-align: center;
}
.suportBox {
  text-align: center;
}
.joinBox span,
a {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.suportBox a {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

@media screen and (max-width: 900px) {
  .joinContainer {
    width: 95%;
    margin: 100px auto;
  }
}
