.prod-main-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  padding: 15px;
  margin: 10px 0;
  width: 100%;
  height: 100vh;
  overflow: scroll;
}
.prod-lists-box {
  padding: 20px 25px;
  background-color: var(--white-color);
  border: 2px solid var(--grey-light);
  position: relative;
}
.prod-catBox {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(7, 182, 7);
  color: #fff;
}
.prod-catBox span {
  font-size: 11px !important;
}
.name-price-box {
  margin-top: 10px;
}
.prod-lists-box h3 {
  font-weight: 500;
  margin: 10px 0;
  margin-left: 5px;
  font-size: 15px;
}
.prod-lists-box hr {
  border-top: 1px solid var(--grey-light);
  margin: 10px 0;
}
.prod-lists-box span {
  display: inline-block;
  margin: 5px;
  font-size: 12px;
  font-weight: 500;
}
.prod-icon-fa-circle {
  font-size: 5px;
  transform: translateY(1px);
  color: var(--dark-primary);
}
.prod-image-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}
.prod-image-box img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.prod-cat-settings-box {
  display: flex;
}
.prod-settings {
  position: absolute;
  top: 0;
  right: 0;
}
.desc-main {
  height: 45px;
  overflow: auto;
}
.icon-prod {
  font-size: 18px;
  margin-left: 5px;
  cursor: pointer;
  color: var(--dark-primary);
}
.del-icon {
  color: red;
  font-size: 20px;
  transform: translateY(1px);
}
.prod-name-box {
  width: 95%;
}
.prod-name-box span {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.all-shops-container {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
}

@media screen and (max-width: 869px) {
  .prod-main-div {
    grid-template-columns: repeat(2, 1fr);
  }
  .prod-name-box {
    width: 100%;
  }
  .prod-name-box span {
    display: inline;
    white-space: unset;
    text-overflow: unset;
    overflow: unset;
  }
  .desc-main {
    height: 60px;
    overflow: auto;
  }
}

@media screen and (max-width: 570px) {
  .prod-main-div {
    grid-template-columns: repeat(1, 1fr);
  }
  .desc-main {
    height: unset;
    overflow: unset;
  }
  .prod-lists-box {
    padding: 20px;
  }
}
