.unsubscribe__box{
    width: 35%;
    margin: 100px auto;
    background-color: #fff;
    padding: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 5px;
}
.unsubscribe__content{
    margin: 20px 0;
}
.unsubscribe__content p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}
.enquiries__box span{
     font-size: 13px;
}
.link__support {
    color: var(--primary-color);
    font-size: 13px;
    font-weight: 600;
}


@media screen and (max-width: 937px){
    .unsubscribe__box{
        width: 60%;
    }
}

@media screen and (max-width: 568px){
    .unsubscribe__box{
        width: 95%;
    }
}
