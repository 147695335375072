.home__footer {
  background-color: var(--primary-color);
  padding: 30px 20px;
  color: var(--white-color);
}
.app-logo,
.android-logo {
  cursor: pointer;
}
.home__footer-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.newsletter-box h3 {
  font-size: 15px;
}
.download__platform img {
  margin-left: 5px;
}
.main__footer-links {
  display: flex;
  justify-content: space-between;
  margin: 30px 10px;
}
.home__footer-links h3 {
  font-size: 15px;
  font-weight: 500;
}
.home__footer-links a {
  display: block;
  font-size: 12px;
  color: rgb(240, 239, 239);
  line-height: 30px;
}

@media screen and (max-width: 936px) {
  .newsletter-box {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .newsletter-box {
    display: none;
  }
  .download__platform {
    margin-top: 10px;
  }
  .home__footer {
    padding: 30px 15px;
  }
  .app-logo {
    width: 110px;
  }
  .home__footer-box {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  .android-logo {
    width: 110px;
  }
  .foot-input {
    display: block;
  }
  .newsletter-box {
    margin: 15px 0;
  }
  .home__footer-links h3 {
    margin-top: 10px;
  }
  .main__footer-links {
    display: flex;
    justify-content: space-around;
  }
  .home__footer-links h3 {
    font-size: 12px;
  }

  .home__footer-links a {
    font-size: 11px;
  }
}

@media screen and (max-width: 582px) {
  .main__footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: unset;
  }
}

@media screen and (max-width: 425px) {
  .home__footer-box {
    display: block;
    text-align: unset;
    padding: 0;
  }
  .foot-input {
    display: block;
  }

  .newsletter-box h3 {
    font-size: 14px;
  }

  .main__footer-links {
    display: block;
    text-align: unset;
  }
  .home__footer-links h3 {
    margin-top: 10px;
  }

  .home__footer-links a {
    line-height: 25px;
  }
}
