.mainb {
  width: 50%;
  margin: 20px auto;
}
.veribox {
  text-align: center;
}
.seller-vBody {
  background-color: var(--white-color);
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
}
.verify-box h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  text-align: center;
  color: #3a3838;
}
.verify-formBox {
  padding: 0 20px;
}
.vboxicons {
  margin: 10px 0;
}
.vbox-icon {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.vbox-p1 {
  font-size: 15px;
  font-weight: 500;
  color: var(--main-grey);
}
.iconv2 {
  font-size: 13px;
  color: var(--main-grey);
}
.vbox-icon span {
  font-size: 14px;
  color: var(--main-grey);
  font-weight: 500;
  margin-left: 5px;
}
.form-verifymain {
  margin-top: 15px;
  border-top: 1px solid var(--grey-light);
  padding: 10px 0;
}
.form-verifymain label {
  font-weight: 700;
  font-size: 14px;
  color: var(--main-grey);
}
.choose-box {
  display: flex;
  align-items: center;
  width: max-content;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding: 10px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
.choose-box:hover {
  background-color: #f8f2f2;
}
.choose-box span {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 600;
  color: var(--main-grey);
}
.choose-icon {
  font-size: 23px;
  color: var(--main-grey);
}
.verify-btnbox {
  border-top: 1px solid var(--grey-light);
  padding: 10px 0;
  text-align: center;
}
.verify-btnbox button {
  padding: 15px 50px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #fff;
  margin-bottom: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
.verify-btnbox button:hover {
  background-color: var(--primary-hover);
}
.card-img {
  display: inline-block;
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid var(--grey-light);
  cursor: pointer;
}
.flex-imagebox {
  display: flex;
  align-items: center;
}
.flex-imagebox2 {
  margin-left: 10px;
}

@media screen and (max-width: 898px) {
  .mainb {
    width: 80%;
  }
}
@media screen and (max-width: 665px) {
  .mainb {
    width: 95%;
  }
}
