.purchase-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #0000005e;
  z-index: 5000;
}
.purchase-header {
  font-size: 20px;
}
.purchase-content {
  position: fixed;
  width: 40%;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 6000;
  border-radius: 5px;
}
.purchase-item {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.type-item {
  font-weight: 500;
  font-size: 13px;
  color: #7e7d7d;
  padding: 5px 0;
}
.item-main {
  font-weight: 600;
  font-size: 15px;
}
.purchase-btn-box {
  padding: 10px 0;
}
.purchase-btn-box button {
  width: 100%;
  padding: 13px 10px;
  color: #fff;
  border: none;
  background-color: var(--primary-color);
  border-radius: 3px;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.purchase-btn-box button:hover {
  background-color: var(--primary-hover);
}
