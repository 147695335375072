.home__advert-top img {
  width: 100%;
}
.hide-body {
  display: none;
}
.content-box {
  z-index: 100000;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.content-box span {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}
.topBox {
  margin-top: 10px;
}

.error-content-box {
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  z-index: 100000;
  border-radius: 5px;
}
.error-icon-box-conbxmain {
  background-color: rgb(255, 158, 158);
  width: max-content;
  padding: 3px 5px;
  border-radius: 100px;
}
.error-content {
  display: flex;
  align-items: center;
}
.error-icon {
  color: rgb(216, 32, 32);
  transform: translateY(1px);
  font-size: 20px;
}
.spantext {
  margin-left: 10px;
  font-weight: 500;
}
.business__info {
  width: 45%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: var(--shadow);
  border-radius: 5px;
}
.business__name {
  text-align: center;
}
.business__name span {
  background-color: var(--secondary-color);
  color: var(--primary-dark);
  font-weight: 500;
  font-size: 15px;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 100px;
  margin-top: 10px;
}
.business__name p {
  margin: 10px 0;
  font-weight: 600;
  font-size: 15px;
}
.shop-name,
.shop_pho {
  font-size: 14px;
  margin-left: 5px;
  font-weight: 500;
}
.phone__ic {
  font-size: 22px;
  background-color: var(--light-primary);
  padding: 3px;
  border-radius: 5px;
}
.shop_pho {
  display: inline-block;
  color: black;
  margin: 10px 0;
  margin-left: 10px;
}
.box-info-data {
  display: flex;
  align-items: center;
}
.shop__locate {
  font-size: 20px;
  background-color: var(--light-primary);
  padding: 3px;
  border-radius: 5px;
}
.carobox {
  display: flex;
  margin: 0 20px;
  margin-bottom: 10px;
  margin-top: 20px;
  border-radius: 10px;
}
.boxCatView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  cursor: pointer;
}
.boxCatView button {
  background-color: #fff;
  color: #000;
  padding: 8px 15px;
  border: none;
  border-radius: 100px;
  font-size: 11px !important;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  font-weight: 500;
}
.boxCatView button:hover {
  background-color: var(--primary-hover);
  color: #fff;
}
.boxCatView p {
  font-size: 13px;
}
.boxCatView input {
  cursor: pointer;
}
.categorySide {
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}
.categorySide h2 {
  color: #000;
  font-size: 18px;
}
.categorySide p {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  margin: 10px 0;
}
.carouseBox {
  margin: 0 10px;
}
.carobox img {
  display: block;
  width: 100%;
  height: 45vh;
}
.boxMd2Flex {
  display: flex;
  align-items: center;
}
.boxMd2Flex p {
  margin-left: 5px;
}
.mobileCategoryBox1 {
  background-color: #fff;
  padding: 5px 10px;
  margin: 0 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  display: none;
}
.mobileCategoryBox1 h4 {
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.mobileCategory {
  display: flex;
  align-items: center;
  overflow: scroll;
}
.categoryBtnBox button {
  display: inline-block;
  width: 110px;
  background-color: var(--light-primary);
  color: var(--primary-dark);
  padding: 10px;
  border: none;
  border-radius: 100px;
  margin-right: 10px;
  font-size: 11px;
  font-weight: 600;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.advertBoxMain {
  width: 80%;
  margin: 20px auto;
  background-color: var(--light-primary);
  padding: 10px;
  border-radius: 10px;
}
.advertBoxMain p {
  font-weight: 600;
  text-align: center;
  color: var(--primary-dark);
  font-size: 14px;
}
@media screen and (max-width: 820px) {
  .error-content-box {
    width: 90%;
  }
}
@media screen and (max-width: 765px) {
  .categorySide {
    display: none;
  }
  .carobox {
    display: block;
    padding: 0;
  }
  .carobox img {
    width: 100%;
    height: 20vh;
    object-fit: unset;
    border-radius: 3px;
  }
  .mobileCategoryBox1 {
    display: block;
  }
  .carouseBox {
    margin: 0;
  }
}

@media screen and (max-width: 573px) {
  .error-icon {
    font-size: 17px;
  }
  .spantext {
    font-size: 12px;
  }
  .business__info {
    width: 95%;
  }
  .carobox {
    padding: 0 10px !important;
    margin: 10px 0;
  }
  .advertBoxMain {
    display: none;
  }
}
