.shop-box{
     padding: 30px;
     border-radius: 5px;
     text-align: center;
     transition: all .3s ease-in;
     box-shadow: 10px 5px 10px rgba(0,0,0,.2);
     cursor: pointer;
}
.shop-box h2{
     font-size: 18px;
     font-weight: 600;
     margin-left: 3px;
}
.shop-box-account{
     background-color: var(--primary-color);
     color: var(--white-color);
}
.shop-box-shops{
     background-color: var(--secondary-color);
     color: var(--black-color);
}
.shop-box-flex {
     display: flex;
     align-items: center;
     justify-content: space-between;   
}

@media screen and (max-width: 989px) {
   .shop-box h2{
          font-size: 16px;
     }
     .shop-box-flex {
          justify-content:unset;   
     }
     .shop-box{
          padding: 20px;
           text-align: unset;
     }
}

@media screen and (max-width: 502px){
     .shop-box h2{
          font-size: 16px;
     }
     .shop-box-flex {
          justify-content:unset;   
     }
     .shop-box{
          padding: 20px;
         
     }
     
}

@media screen and (max-width: 449px){
     .shop-box h2{
          font-size: 16px;
     }
     .shop-box-flex {
          justify-content:unset;   
     }
     .shop-box{
          padding: 20px;
          /* margin: 10px 0; */
     }
     
}


